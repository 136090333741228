import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import TypeToBlock from "../components/type-to-block";

// TODO: Move to templates.
export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    stripePlanMonthly: stripePlans(interval: { eq: "month" }) {
      id
      currency
      amount
      interval
      interval_count
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
    home: contentfulPages(
      slug: { eq: "home" }
      node_locale: { eq: $languageISO }
    ) {
      slug
      id
      metaDescription
      metaTitle
      modules {
        id
        heading
        name
        subheading
        type
        items {
          ... on ContentfulPlanModule {
            id
            planSlug
            planName
            shortDescription
            featureImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            featureImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          ... on ContentfulItems {
            body
            heading
            id
            branchLink
            link
            linkHelpText
            linkText
            subheading
            desktopImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            mobileImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            video
          }
        }
      }
    }
  }
`;

const Home = ({
  data: {
    home: { metaTitle, metaDescription, modules },
    stripePlanMonthly,
    ribbon,
    header,
    footer,
  },
}) => {
  return (
    <Layout header={header} ribbon={ribbon} footer={footer} >
      <Seo title={metaTitle} metaDescription={metaDescription} />
      {modules.map((module, key) => (
        <TypeToBlock
          key={`module-${key}`}
          stripePlanMonthly={stripePlanMonthly}
          module={module}
        />
      ))}
    </Layout>
  );
};

export default Home;
